import { defineStore } from 'pinia'
import commonStore from '../common'

export const storeName = 'uniemensDipteDettaglioStore'

const useUniemensDipteDettaglioStore = defineStore(
    storeName,
    commonStore.baseUniemensDettaglioState,
    {
        persist: false,
    },
)

export default useUniemensDipteDettaglioStore
