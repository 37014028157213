import { useGlobalStore } from '@/stores'
import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    GROUP: 'uniemens',
    ICON: 'fa-regular fa-table-list',
    TITLE: 'Uniemens',
    CODVOCEMENU: '000011',
    CODPERMESSO: 'TABELLE_SP', //TODO: DA CAMBIARE CHIEDERE A Davide
}

export const SETTINGS = Object.freeze({
    DITTE_LISTA: {
        TITLE: 'Elenco Ditte - Uniemens - Gestione - Paghe',
        LABEL: 'Elenco Ditte Uniemens',
        PATH: 'uniemensDitteList',
        NAME: 'ElencoDitteUniemens',
        TAB: { ...tabPropsDefault },
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.DITTE_LISTA.PATH,
            name: SETTINGS.DITTE_LISTA.NAME,
            component: pagheViews.uniemens.Elenco,
            meta: {
                title: SETTINGS.DITTE_LISTA.TITLE,
                permissions: {
                    codPermesso: tabPropsDefault.CODPERMESSO,
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: true,
        },
    ],
}
