import App from './App.vue'
import mitt from 'mitt'
import moment from 'moment'
import newRouter from '@/router/newRouter'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import piniaPluginResetStore from '@/stores/plugins/reset-store-plugin'
import vuetify from './plugins/vuetify'
import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { FocusTrap } from 'focus-trap-vue'
import { loadFonts } from './plugins/webfontloader'
import 'handsontable/dist/handsontable.full.css'
// servono ??
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap-grid.min.css'
import 'bootstrap'
// ??

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(piniaPluginResetStore)

loadFonts()

const app = createApp(App)
app.component('FocusTrap', FocusTrap)
app.provide('emitter', mitt())
app.provide('moment', moment)
app.provide('pinia', pinia)
app.use(newRouter)
app.use(vuetify)
app.use(pinia)
app.mount('#app')
