import schedulazioni from './schedulazioni'
import utility from './utility'

const mapMenuModals = new Map([
    ['000191', utility.duplica.ditta.Duplica],
    ['000192', utility.duplica.lavoratore.Duplica],
    ['000193', utility.elimina.ditta.Elimina],
    ['000194', utility.elimina.lavoratore.Elimina],
])

export { mapMenuModals, schedulazioni, utility }
