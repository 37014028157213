import { computed, reactive, watch } from 'vue'
import { useRouter } from 'vue-router'

import { GLOBALS } from '@/constants'
import { useDiario } from '@/composables/diarioModal'
import { usePage } from '@/composables/page'
import pagheRoutes from '@paghe/routes'
import { ROUTES_DIPENDENTE } from '@/routes/route-paghe-dipendente' //TODO da sostituire con modul
//import { ROUTES_COLLABORATORE } from '@/routes/route-paghe-collaboratore' //TODO da sostituire con modul
import type { BaseUniemensDipteProps } from '../scripts'

export function usePageDipteDettaglioUniemens(
    props: BaseUniemensDipteProps,
    isCollaboratore: boolean = false,
) {
    const router = useRouter()
    const gestione: Gestione = 'crr'

    const bottomBarListArchivi: BottomBarList[] = [
        {
            title: isCollaboratore ? 'Anagrafica collaboratore' : 'Anagrafica dipendente',
            enabled: true,
            action: () => {
                router.replace({
                    name: ROUTES_DIPENDENTE.DIPENDENTE_ANAGRAFICA.NAME,
                    params: {
                        ...props,
                        mod: GLOBALS.DATA_MODE.MOD,
                        gestione,
                    },
                })
            },
        },
        {
            title: 'Anagrafica ditta',
            enabled: true,
            action: () => {
                router.replace({
                    name: pagheRoutes.anagrafiche.ditte.SETTINGS.DITTA_ANAGRAFICA.NAME,
                    params: {
                        id: props.dittaId,
                        idPadre: 0,
                        mod: GLOBALS.DATA_MODE.MOD,
                        month: props.month,
                        year: props.year,
                        gestione,
                    },
                })
            },
        },
        {
            title: 'Risultati dipendente',
            enabled: true,
            action: () => {
                router.replace({
                    name: pagheRoutes.risultati.dettaglioDp.SETTINGS.DIPENDENTE_RISULTATI.NAME,
                    params: { ...props }, //sono identiche
                })
            },
        },
    ]

    if (props.dittaPadreId > 0) {
        bottomBarListArchivi.push({
            title: 'Anagrafica UP',
            enabled: true,
            action: () => {
                router.replace({
                    name: pagheRoutes.anagrafiche.ditte.SETTINGS.DITTA_ANAGRAFICA.NAME,
                    params: {
                        id: props.dittaId,
                        idPadre: props.dittaPadreId,
                        mod: GLOBALS.DATA_MODE.MOD,
                        month: props.month,
                        year: props.year,
                        gestione,
                    },
                })
            },
        })
    }
    const bottomBarListTabelle: BottomBarList[] = []

    return {
        bottomBarListArchivi,
        bottomBarListTabelle,
    }
}

export function usePageDipteUniemens(
    props: BaseUniemensDipteProps,
    pageStore: any, //dettaglioStore
    isCollaboratore: boolean = false,
) {
    const page = usePage()
    const refDiario = reactive({
        pageName: 'DiarioUniemenDipte',
        type: isCollaboratore ? 'collaboratore' : 'dipendente',
        periodo: { periodoDiRiferimento: pageStore.getPeriodoFromParametri(true) },
    })

    const { openDiarioDipte, addDiarioToSavePayload } = useDiario(refDiario)

    const getPagePermission = computed(() => !pageStore.unlockForm || page.disableField.value)

    //watch(enableBottomButtons, val => pageStore.setToSave(val)) //NECESSARIIO PER IL DETTAGLIO

    return {
        addDiarioToSavePayload,
        openDiarioDipte,
        getPagePermission,
    }
}
