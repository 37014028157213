import { call } from '@/scripts/lib/http'
import { AxiosResponse } from 'axios'

const BASE_URL = '/ElabDipteUE'

class API {
    @call({ url: `${BASE_URL}/GetElabDipteUE` })
    static async get(
        payload: Payload,
    ): Promise<AxiosResponse<BasicResponseData<ElabDipteUE>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveDipteElabUE` })
    static async save(
        payload: SaveElabDipteUE,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveElabDipteUE>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveUniemensImponibileContributiVariazioniRetrib` })
    static async saveUniemensImponibileContributi(
        payload: SaveUniemensImponibileContributi,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveUniemensImponibileContributi>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/SaveUniemensEventiCigInfoAgg` })
    static async saveUniemensEventiCigInfoAgg(
        payload: SaveUniemensEventiCigInfoAgg,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveUniemensEventiCigInfoAgg>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/saveUniemensSettimaneDifferenzeGiorno` })
    static async saveUniemensSettimaneDifferenzeGiorno(
        payload: SaveUniemensSettimaneDifferenzeGiorno,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveUniemensSettimaneDifferenzeGiorno>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/saveUniemensSportSpettacolo` })
    static async saveUniemensSportSpettacolo(
        payload: SaveUniemensSportSpettacolo,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveUniemensSportSpettacolo>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveUniemensContrSindacaleContrAtipica` })
    static async SaveUniemensContrSindacaleContrAtipica(
        payload: SaveUniemensContrSindacaleContrAtipica,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveUniemensContrSindacaleContrAtipica>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/SaveUniemensFondiSpeciali` })
    static async SaveUniemensFondiSpeciali(
        payload: SaveUniemensFondiSpeciali,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveUniemensFondiSpeciali>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/saveUniemensLavorantiDomicilio` })
    static async saveUniemensLavorantiDomicilio(
        payload: SaveUniemensLavorantiDomicilio,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveUniemensLavorantiDomicilio>> | null> {
        return null
    }

    @call({ url: `${BASE_URL}/saveUniemensPreavvisoBonus` })
    static async saveUniemensPreavvisoBonus(
        payload: SaveUniemensPreavvisoBonus,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveUniemensPreavvisoBonus>> | null> {
        return null
    }
    @call({ url: `${BASE_URL}/saveUniemensTFRMisureDestinazione` })
    static async saveUniemensTFRMisureDestinazione(
        payload: SaveUniemensTFRMisureDestinazione,
    ): Promise<AxiosResponse<BasicResponsePayload<SaveUniemensTFRMisureDestinazione>> | null> {
        return null
    }
}

export default {
    get: API.get,
    save: API.save,
    saveUniemensImponibileContributi: API.saveUniemensImponibileContributi,
    saveUniemensEventiCigInfoAgg: API.saveUniemensEventiCigInfoAgg,
    saveUniemensSettimaneDifferenzeGiorno: API.saveUniemensSettimaneDifferenzeGiorno,
    saveUniemensSportSpettacolo: API.saveUniemensSportSpettacolo,
    saveUniemensContrSindacaleContrAtipica: API.SaveUniemensContrSindacaleContrAtipica,
    saveUniemensFondiSpeciali: API.SaveUniemensFondiSpeciali,
    saveUniemensLavorantiDomicilio: API.saveUniemensLavorantiDomicilio,
    saveUniemensPreavvisoBonus: API.saveUniemensPreavvisoBonus,
    saveUniemensTFRMisureDestinazione: API.saveUniemensTFRMisureDestinazione,
}
