import { computed, shallowReactive, ref, reactive } from 'vue'
import { defineStore } from 'pinia'

import { GLOBALS } from '@/constants'
import { pagheRoutes } from '@/routes'

import { useDynamicStore } from '@/composables/dynamic-store'
import { useBaseTable } from '@/composables'

const baseTable = useBaseTable()

export default defineStore(
    'exportDefStore',
    () => {
        const itemReadOnly = ref(false)
        const activeChildName = ref()
        const actualIndex = ref(0)
        const dataLookup = shallowReactive(new Map())
        const commonData = reactive({
            codice: null,
            descrizione: null,
        })
        const dynamicStore = useDynamicStore()
        const elenco = reactive({
            ...baseTable.baseTableOptions(),
            sortBy: [{ key: 'codice', order: 'asc' }],
            selectedKeys: true,
            tipoFiltro: [1, 2],
        })
        const localTab = ref(0)
        const newId = ref(0)
        const state = reactive({
            changeMap: null,
            childName: null,
            data: null,
            props: null,
            routeList: pagheRoutes.exports.definizioni.SETTINGS.ELENCO,
        })

        const dataChildFilled = computed(() => {
            return state.data && state.childName === activeChildName.value
        })

        const navLength = computed(() =>
            Array.isArray(elenco.selectedKeys) ? elenco.selectedKeys.length : 0,
        )

        async function getData(props: any) {
            // Eventuali dati da API per tutte le pagine
        }

        async function initStateData() {}

        function getPropsNavigation(idx: number) {
            try {
                return { id: (elenco.selectedKeys as any)[idx], mod: GLOBALS.DATA_MODE.MOD }
            } catch (error) {
                null
            }
        }

        function resetActiveChildName() {
            activeChildName.value = pagheRoutes.exports.definizioni.SETTINGS.GENERALE.NAME
        }

        function resetPageData(resetLookup: boolean = true, navigation: boolean) {
            state.childName = null
            state.data = null
            state.changeMap = null
            if (!navigation) localTab.value = 0
            if (resetLookup) dataLookup.clear()
        }

        function samePeriod() {
            return true
        }

        return {
            ...dynamicStore,
            activeChildName,
            actualIndex,
            commonData,
            dataChildFilled,
            dataLookup,
            elenco,
            getData,
            getPropsNavigation,
            initStateData,
            localTab,
            navLength,
            newId,
            resetActiveChildName,
            resetPageData,
            samePeriod,
            state,
            itemReadOnly,
        }
    },
    {
        persist: {
            paths: ['itemReadOnly'],
        },
    },
)
