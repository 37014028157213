import type { ShallowReactive } from 'vue'
import { shallowReactive } from 'vue'
import { Store, defineStore } from 'pinia'

import { DynamicStore, useDynamicStore } from '@/composables/dynamic-store'
import { useBaseTable } from '@/composables'

const id = 'movimentiElencoDitte'

type Elenco = BaseTableOptions & {
    filtroDitteElaborate: boolean
    filtroDitteNoDipti: boolean
    filtroDitteTipo: number[]
    filtroDitteUP: boolean
}

type DittaSelez = {
    codice: string
    codiceUP?: string | null
    ragioneSociale: string
}

export type ElencoDitteMovStore = Store &
    DynamicStore & {
        baseTable: ShallowReactive<Elenco>
        dittaSelez: ShallowReactive<DittaSelez>
    }

const store = defineStore(
    id,
    () => {
        const baseTable: ShallowReactive<Elenco> = shallowReactive({
            ...useBaseTable().baseTableOptions(),
            sortBy: [
                { key: 'codiceDitta', order: 'asc' },
                { key: 'unitaProduttiva', order: 'asc' },
            ],
            filtroDitteElaborate: true,
            filtroDitteNoDipti: false,
            filtroDitteTipo: [1, 2],
            filtroDitteUP: false,
        })

        const dittaSelez: ShallowReactive<DittaSelez> = shallowReactive({} as DittaSelez)

        return {
            ...useDynamicStore(),
            baseTable,
            dittaSelez,
        }
    },
    {
        persist: [
            {
                paths: ['dittaSelez'],
            },
        ],
    },
)

export default {
    id,
    store,
}
