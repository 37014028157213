import { RouteLocation } from 'vue-router'

import pagheViews from '@paghe/views'

const tabPropsDefault: TabProps = {
    CODVOCEMENU: '000008',
    GROUP: 'movimentiMese',
    ICON: 'far fa-calendar-circle-user',
    TITLE: 'Movimenti',
}

const SETTINGS = Object.freeze({
    ELENCO_DITTE: {
        NAME: 'MovDiptiElencoDitte',
        PATH: 'movimenti/dipendenti/elenco-ditte',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Movimenti mese dipendenti',
    },
    ELENCO_DIPENDENTI: {
        NAME: 'MovDiptiElencoDipendenti',
        PATH: 'movimenti/dipendenti/elenco-dipendenti/:modalita/:idDitta/:idUP/:periodo',
        TAB: { ...tabPropsDefault },
        TITLE: 'Paghe - Movimenti mese dipendenti',
    },
})

export default {
    SETTINGS,
    routes: [
        {
            path: SETTINGS.ELENCO_DITTE.PATH,
            name: SETTINGS.ELENCO_DITTE.NAME,
            component: pagheViews.movimenti.dipendenti.ElencoDitte,
            meta: {
                title: SETTINGS.ELENCO_DITTE.TITLE,
                permissions: {
                    codPermesso: 'MOVIMENTO',
                    codVoceMenu: tabPropsDefault.CODVOCEMENU,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
        },
        {
            path: SETTINGS.ELENCO_DIPENDENTI.PATH,
            name: SETTINGS.ELENCO_DIPENDENTI.NAME,
            component: pagheViews.movimenti.dipendenti.ElencoDipendenti,
            meta: {
                title: SETTINGS.ELENCO_DIPENDENTI.TITLE,
                permissions: {
                    codPermesso: 'MOVIMENTO',
                    codVoceMenu: null,
                },
                tabGroup: tabPropsDefault.GROUP,
            },
            props: (route: RouteLocation) => ({
                modalita: route.params.modalita,
                idDitta: Number(route.params.idDitta),
                idUP: Number(route.params.idUP),
                periodo: Number(route.params.periodo),
            }),
        },
    ],
}
