import aliquote from './aliquote'
import minimaliMassimali from './minimaliMassimali'
import retribuzione from './retribuzione'
import scadenze from './scadenze'
import sgravi from './sgravi'
import sindacato from './sindacato'
import varie from './varie'
export default {
    aliquote,
    minimaliMassimali,
    retribuzione,
    scadenze,
    sgravi,
    sindacato,
    varie,
}
