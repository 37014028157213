import { get, set } from 'lodash'

type ArrayType = Array<object>
type MapType = Map<any, object | Array<object>>
type MapKeyType = number | string

/**
 * Crea e restituisce un mappa di oggetti partendo da e un array di oggetti.
 * @array L'array di oggetti da convertire in mappa
 * @fields Lista dei campi di ogni elemento dell'array da riportare in mappa. Accetta anche percorsi
 * nidificati (dot notation). Se NULL, riporta l'elemento intero in mappa
 * @keyName campo item da utilizzare come chiave di scrittura della mappa. Se NULL, utilizza
 * l'indice di iterazione dell'array
 * @toArray indica se nella mappa l'oggetto viene inseritop in un array. Es. nel caso di oggetti diversi con medesima chiave
 */
export function arrayToMap<T extends object>(
    array: T[] | null | undefined,
    fields: Array<string> | null = null,
    keyName: string | null = null,
    toArray: boolean = false,
): Map<any, T | T[]> {
    const map: Map<any, T | T[]> = new Map()
    array?.forEach((item: T, index: number) => {
        const key = keyName ? get(item, keyName, index) : index
        let itemMap: T = item
        if (fields && fields?.length) {
            fields.forEach((field: string) => set(itemMap, field, get(item, field, undefined)))
        }
        if (!toArray) {
            map.set(key, itemMap)
        } else {
            if (map.has(key)) {
                const items = map.get(key)
                if (Array.isArray(items)) items.push(itemMap)
                return
            }
            map.set(key, [itemMap])
        }
    })
    return map
}

/**
 * Crea e restituisce un array di oggetti partendo da una mappa di oggetti
 * @map La mappa di oggetti da convertire in a array
 * @fields Lista dei campi di ogni elemento del map da riportare nell'oggetto dell'array. Accetta
 * anche percorsi nidificati (dot notation). Se NULL, riporta l'elemento intero in array
 * @sort Se true riordina l'array di output per chiave mappa
 */
export function mapToArray<T extends object>(
    map: Map<any, T | T[]>,
    fields: Array<string> | null = null,
    sort = true,
): ArrayType {
    const array: object[] = []
    let keys = Array.from(map.keys())
    if (sort) keys = keys.sort()
    keys.forEach((key: string | number) => {
        const item = map.get(key)
        if (!item) return
        let itemArray = item
        if (fields && fields?.length) {
            fields.forEach((field: string) => set(itemArray, field, get(item, field, undefined)))
        }
        array.push(itemArray)
    })
    return array
}
