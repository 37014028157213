import { defineStore } from 'pinia'
import { computed, type ComputedRef, reactive, ref, type Ref, shallowReactive } from 'vue'

import aliquoteRoutes from '@paghe/routes/tabelle/aliquote'
import { useDynamicStore } from '@/composables/dynamic-store'
import { pagheAPI } from '@/api'
import type { NoteGenProps } from '@/components/NoteGen.vue'
import { LookupMap } from '@/scripts/services/queryLookup'

export const storeName = 'tabelleAliquoteStore'

const useAliquoteStore = defineStore(
    storeName,
    () => {
        const _isNote = ref(false)
        const activeChildName: Ref<string | null> = ref(null)
        const dataLookup = reactive(new LookupMap())
        const dataChild: { name: string | null; origin: any; extraData: any } = shallowReactive({
            name: null, //Nome del dettaglio a cui appartengono i dati in dataChild
            origin: null, //Dati da ripristinare al cambio TAB
            extraData: null,
        })
        const localTab = ref(0)
        const state: any = reactive({
            data: null,
        })

        //#region GETTER'S
        const dataChildFilled = computed(() => {
            return (
                dataChild.origin /* || dataChild._grid */ &&
                dataChild.name === activeChildName.value
            )
        })

        const isNote = computed({
            get: () => _isNote.value,
            set: (val: boolean) => (_isNote.value = val),
        })

        const noteContext: ComputedRef<NoteGenProps> = computed(() => ({
            title: 'Aliquote',
            id: null,
            entita: 'Aliquota',
            codice: null,
            isNote,
        }))
        //#endregion

        //#region ACTION'S
        function resetPageData(resetLookup: boolean = true, navigation: boolean = false) {
            resetBaseData(resetLookup, navigation)
            //reset dati aggiuntivi
        }

        function resetBaseData(resetLookup: boolean = true, navigation: boolean = false) {
            dataChild.name = null
            dataChild.origin = null //fix per datalookup uguali non scatta il watch
            dataLookup.clear()
            if (!navigation) {
                //operazioni da fare solo se non si sta navigando tra le ditte
                localTab.value = 0
            }
        }

        function resetActiveChildName() {
            activeChildName.value = aliquoteRoutes.SETTINGS.ALIQUOTE.NAME
        }
        async function getDataNote(data: NoteGenProps | null = null) {
            if (!data) data = noteContext.value

            let filter = [['entita', '=', data.entita]]
            if (data.codice != null) {
                filter = [['entita', '=', data.entita], 'and', ['codice', '=', data.codice]]
            }

            const response = await pagheAPI.note.noteGen.get({
                filter: JSON.stringify(filter),
                select: JSON.stringify(['id']),
            })
            if (response?.data.responseStatus.isSuccessful && response?.data.data) {
                if (response.data.data.length > 0) {
                    _isNote.value = true
                } else {
                    _isNote.value = false
                }
            }
        }
        //#endregion

        return {
            ...useDynamicStore(),
            activeChildName,
            dataChildFilled,
            dataChild,
            isNote,
            state,
            localTab,
            resetPageData,
            resetActiveChildName,
            dataLookup,
            getDataNote,
            noteContext,
        }
    },
    {
        persist: false,
    },
)

export default useAliquoteStore
