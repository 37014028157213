import type { ShallowReactive } from 'vue'
import { shallowReactive } from 'vue'
import { Store, defineStore } from 'pinia'

import { DynamicStore, useDynamicStore } from '@/composables/dynamic-store'
import { useBaseTable } from '@/composables'

const id = 'movimentiElencoDipendenti'

type Elenco = BaseTableOptions & {
    unitaProduttiva: string | null
}
export type ElencoDiptiMovStore = Store &
    DynamicStore & {
        baseTable: ShallowReactive<Elenco>
    }

const store = defineStore(id, () => {
    const baseTable: ShallowReactive<any> = shallowReactive({
        ...useBaseTable().baseTableOptions(),
        sortBy: [
            { key: 'unitaProduttiva', order: 'asc' },
            { key: 'codice', order: 'asc' },
        ],
        unitaProduttiva: null,
    })

    return {
        ...useDynamicStore(),
        baseTable,
    }
})

export default {
    id,
    store,
}
