import { get, has, keys, set } from 'lodash'

/**
 * Aggiorna le proprietà di target con le rispettive proprietà di source, scartando quelle presenti
 * solo in source.
 * @param target object di destinazione di cui aggiornare le proprietà
 * @param source object sorgente da cui copiare le proprietà
 */
export function assignPropsFromObject(target?: Object | null, source?: Object | null) {
    if (!target || !source) return
    Object.keys(target).forEach((key: string) => {
        if (!has(source, key)) return
        const val = get(target, key)
        if (typeof val === 'object' && !Array.isArray(val) && keys(val).length) {
            assignPropsFromObject(val, get(source, key))
        } else {
            set(target, key, get(source, key, null))
        }
    })
}
