import { computed, shallowReactive, ref, Ref, ComputedRef } from 'vue'
import { GLOBALS } from '@/constants'

import { toObject } from '@/scripts/services/date'
import { pagheAPI } from '@/api'

export interface ElencoLavoratoriState {
    actualIndex: Ref<number>
    data: Ref<any>
    dataDitta: Ref<Partial<DittaResponse>>
    elencoState: ElencoState
    filtroDitteGestite: Ref<boolean>
    filtroDitteTipo: Ref<number[]>
    filtroDitteUP: Ref<boolean>
    fetchDataDitta: (props: any) => Promise<void>
    getPropsNavigation: (index: number) =>
        | {
              year?: string | number | undefined
              month?: string | number | undefined
              day?: string | number | undefined
              id: number
              mod: string
          }
        | undefined
    navLength: ComputedRef<number>
    samePeriod: () => boolean
    unitaProduttiva: Ref<boolean>
}

interface ElencoState {
    upCod: null | string[]
    selectAllOnEnter: boolean
    filtro: string
    selectedKeys: boolean | number[]
    sortBy: { key: string; order: 'asc' | 'desc' }[]
    dataList: any[]
    escludiCessati: 1 | 2
}

function baseElencoLavoratoriState(isCollaboratore: boolean = false): ElencoLavoratoriState {
    const elencoState: ElencoState = shallowReactive({
        upCod: null,
        selectAllOnEnter: true,
        filtro: '',
        selectedKeys: true,
        sortBy: [
            { key: 'codice', order: 'asc' },
            { key: 'unitaProduttiva', order: 'asc' },
        ],
        dataList: [],
        escludiCessati: 2,
    })

    const actualIndex = ref(0)

    //const selectedKeys = ref(true)
    const unitaProduttiva = ref(true)
    //const lastIdx = ref(-1) //index dell'ultimo elemento visualizzato ---> utilizzato per determinare il periodo e di conseguenza se riscaricare i dati delle lookup
    const filtroDitteGestite = ref(true)
    const filtroDitteTipo = ref([1, 2])
    const filtroDitteUP = ref(false)
    const data = ref(null)

    const dataDitta = ref() //contiene i dati della ditta

    async function fetchDataDitta(props: any) {
        const params: DittaPayload = {
            requireHasFutureValidity: false,
            periodoDiRiferimento: {
                year: Number(props.year),
                month: Number(props.month),
            },
            filter: `['id', '=', '${props.id}']`,
            select: JSON.stringify([
                'id',
                'codiceDitta',
                'unitaProduttiva',
                'dittaUPRagioneSociale.denominazioneAggiuntiva',
                'dittaUPRagioneSociale.ragioneSociale',
                'dittaUPOpzione.isDiario',
            ]),
        }
        if (+props.idPadre > 0) {
            //aggiungo alla select anche le info aggiuntive
            let obj = JSON.parse(params.select)
            if (!obj) {
                obj.push(
                    'padre.id',
                    'padre.dittaUPOpzione.isDiario',
                    'padre.dittaUPRagioneSociale.denominazioneAggiuntiva',
                    'padre.dittaUPRagioneSociale.ragioneSociale',
                )

                params.select = JSON.stringify(obj)
            }
        }

        try {
            const response = await pagheAPI.anagrafiche.ditte.ditta.get(params) //pagheAPI.anagrafiche.ditte.ditta.get(params)

            if (response?.data.responseStatus.isSuccessful) {
                dataDitta.value = response?.data?.data?.[0]

                // const isDiario = get(
                //     data[0],
                //     'dittaUPOpzione.isDiario',
                //     get(data[0], 'padre.dittaUPOpzione.isDiario', false),
                // )

                // setDataDitta({
                //     codice: data[0].codiceDitta,
                //     ragSociale: utils.ragSocialeFromData(data[0], true),
                //     isDiario: isDiario,
                // })

                //VIENE PRESO DA DIPLISTSTORE MA IN CASO DI F5 VALUTARE IL REINIT
                //ditta.codice = data[0].ditta.codiceDitta
            }
        } catch (err) {
            console.error('Errore durante la chiamata: ', err)
        }
    }

    function samePeriod() {
        //TODO: VALUTARE SE SEMPRE UGUALE
        return true
    }

    function getPropsNavigation(idx: number) {
        try {
            const item = elencoState.selectedKeys?.[idx] //elemento chiave id+dataInizioDoppioCedolino
            const id = +item.slice(0, item.indexOf('-'))
            const periodoDiRiferimento = toObject(item.slice(item.indexOf('-') + 1))

            return { id: id, mod: GLOBALS.DATA_MODE.MOD, ...periodoDiRiferimento }
        } catch (error) {
            null
        }
    }

    //#region GETTERs
    const navLength = computed(() =>
        Array.isArray(elencoState.selectedKeys) ? elencoState.selectedKeys.length : 0,
    )

    //#endregion

    return {
        actualIndex,
        data,
        dataDitta,
        elencoState,
        filtroDitteGestite,
        filtroDitteTipo,
        filtroDitteUP,
        fetchDataDitta,
        getPropsNavigation,
        navLength,
        samePeriod,
        unitaProduttiva,
    }
}

export default baseElencoLavoratoriState
