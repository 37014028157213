import { defineStore } from 'pinia'
import { computed, reactive, ref, Ref, ShallowReactive, shallowReactive } from 'vue'

import { pagheRoutes } from '@/routes'
import dateUty, { toMoment } from '@/scripts/services/date'
import { useDynamicStore } from '@/composables/dynamic-store'
import { pagheAPI } from '@/api'
import type { NoteGenProps } from '@/components/NoteGen.vue'
import { LookupMap } from '@/scripts/services/queryLookup'
export const storeName = 'tabelleVarieDettaglioStore'

const useDettaglioStore = defineStore(
    storeName,
    () => {
        const activeChildName: Ref<string | null> = ref(null)

        const _isNote = ref(false)

        const focusElementUID = ref(null)
        const dataLookup = reactive(new LookupMap())
        const state: any = reactive({
            data: null,
        })

        const dataChild = shallowReactive({
            name: null, //Nome del dettaglio a cui appartengono i dati in dataChild
            origin: null, //Dati da ripristinare al cambio TAB
            extraData: null,
            _grid: null,
        })

        function resetPageData(resetLookup: boolean = true, navigation: boolean = false) {
            resetBaseData(resetLookup, navigation)
            //reset dati aggiuntivi
        }

        function resetBaseData(resetLookup: boolean = true, navigation: boolean = false) {
            dataChild.name = null
            dataChild.origin = null //fix per datalookup uguali non scatta il watch
            dataChild._grid = null
            dataLookup.clear()
            if (!navigation) {
                //operazioni da fare solo se non si sta navigando tra le ditte
                localTab.value = 0
            }
        }

        function resetActiveChildName() {
            activeChildName.value = pagheRoutes.tabelle.varie.SETTINGS.TABELLE_VARIE.NAME
        }

        const isNote = computed({
            get: () => _isNote.value,
            set: (val: boolean) => (_isNote.value = val),
        })

        const dataChildFilled = computed(() => {
            return (
                dataChild.origin /* || dataChild._grid */ &&
                dataChild.name === activeChildName.value
            )
        })

        const noteContext = computed(() => ({
            title: 'Tabelle varie',
            id: null,
            entita: 'vcodDes',
            codice: null,
            isNote,
        }))

        async function getDataNote(data: NoteGenProps) {
            let filter = [['entita', '=', data.entita]]
            if (data.codice != null) {
                filter = [['entita', '=', data.entita], 'and', ['codice', '=', data.codice]]
            }

            const response = await pagheAPI.note.noteGen.get({
                filter: JSON.stringify(filter),
                select: JSON.stringify(['id']),
            })
            if (response?.data?.responseStatus.isSuccessful) {
                if (response?.data?.data?.length > 0) {
                    _isNote.value = true
                } else {
                    _isNote.value = false
                }
            }
        }
        const localTab = ref(0)

        return {
            ...useDynamicStore(),
            activeChildName,
            dataChildFilled,
            dataChild,
            isNote,
            state,
            localTab,
            resetPageData,
            focusElementUID,
            resetActiveChildName,
            dataLookup,
            getDataNote,
            noteContext,
        }
    },
    {
        persist: false,
    },
)

export default useDettaglioStore
