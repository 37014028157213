import {
    ref,
    reactive,
    Ref,
    shallowRef,
    computed,
    shallowReactive,
    ShallowReactive,
    ShallowRef,
} from 'vue'

import { useGlobalStore } from '@/stores'

import moment from 'moment'
import dateUty, { toMoment } from '@/scripts/services/date'
import { pagheAPI } from '@/api'
import { get, orderBy } from 'lodash'
import { useUtils } from '@/composables'
import { pagheRoutes } from '@/routes'
import { useRoute } from 'vue-router'
import { assignPropsFromObject } from '@/scripts/utils/object'
import { BaseUniemensDipteProps } from '@paghe/views/uniemens/scripts'

export type StateParametriUniemens = Pick<StateParametri, 'periodoDal' | 'periodoAl'>
interface DataHeaderUniemen {
    title: string | string[]
    subtitle: string | undefined
}

interface DataChild {
    name: null | any //Nome del dettaglio a cui appartengono i dati in dataChild
    origin: null | any //Dati da ripristinare al cambio TAB
    extraData: null | any
}

interface DatiDitta {
    ditta: Ditta | null | undefined
    dittaUP: Ditta | null | undefined
}

interface DataPeriodi {
    id: number
    idDittaUp: number
    idElabDipte: number
    idTipoLavoratore: number
    codTipoContribuzione: string
    dataRiferimento: string
    tipoElaborazione: string
    tipoLavoratore: string
    unitaProduttiva: string
}

interface StateUniemens {
    data: DipteResponse | null //DATI TOTALI scaricati in getData
    routeList: string
}

function baseUniemensDettaglioState(isCollaboratore: boolean = false) {
    const utils = useUtils()
    const globalStore = useGlobalStore()
    const route = useRoute()

    //#region VARIABILI D'AMBIENTE
    const activeChildName: Ref<string | null> = shallowRef(null) //Nome del dettaglio attivo
    const basePeriodoDal = dateUty
        .toMoment(`01/${globalStore.state.periodoElab?.year}`)
        ?.startOf('month')
    const basePeriodoAl = dateUty
        .toMoment(`${globalStore.state.periodoElab?.month}/${globalStore.state.periodoElab?.year}`)
        ?.endOf('month')
        .startOf('days')
    const dataLookup = reactive(new Map()) //dati delle lookup (ref poiche con shallowRef non aggiorna le lookup)
    const datiDitta: ShallowRef<DatiDitta | undefined> = shallowRef() //TODO: vedere se serve dati relativi alla ditta scaricati in getData
    const dataHeader: DataHeaderUniemen = reactive({
        //dati visualizati nell'header
        title: 'GENERALE',
        subtitle: undefined,
    })
    const dataChild: DataChild = shallowReactive({
        name: null, //Nome del dettaglio a cui appartengono i dati in dataChild
        origin: null, //Dati da ripristinare al cambio TAB
        extraData: null,
    })
    const dataPeriodi: Ref<DataPeriodi[]> = ref([]) //dati tabella periodi nella pagina Dettaglio
    const diarioStudio = reactive({ downloaded: false, value: false }) //permmesso diario a livello studio
    const diarioDitta = reactive({ value: false, downloaded: false }) //permesso diario a livello ditta
    const isNote = ref(false) //abilitazione - Note
    const state: StateUniemens = reactive({
        data: null, //DATI TOTALI scaricati in getData
        routeList: isCollaboratore //indica la rotta dell'elenco in caso di dipte o collab
            ? pagheRoutes.risultati.dettaglioCo.SETTINGS.COLLABORATORE_LISTA
            : pagheRoutes.uniemens.dipendente.SETTINGS.LISTA,
    })
    const localTab = ref(0) //Indica il subTab attivo nella pagina

    // Periodo dal = 01/anno di PeriodoElaborazione.DataPeriodoElaborazione
    // Periodo al = mese/anno di PeriodoElaborazione.DataPeriodoElaborazione.
    const parametri: ShallowReactive<StateParametriUniemens> = shallowReactive({
        periodoDal: dateUty.toISO(basePeriodoDal),
        periodoAl: dateUty.toISO(basePeriodoAl),
    })
    const selectedPeriod = reactive({ col: -1, row: -1 }) //indica la riga della tabella periodi attualmente selezionata in pagina
    const toSave = ref(false) //indica se il salvataggio e' abilitato in pagina
    const unlockForm = ref(false) //stato del 'lucchetto' presente nell'heade r che abilita o meno la pagina
    //#endregion

    //#region GETTERs
    const getDataPeriodi = computed(() => dataPeriodi.value)
    const getPeriodo = computed(() => {
        return globalStore.state.periodoElab || dateUty.toObject(moment())
    })
    const dataChildFilled = computed(() => {
        return !!dataChild.origin && dataChild.name === activeChildName.value
    })
    const getNoteContext = computed(() => ({
        entityId: state.data?.id,
        entityType: 'DIPTE',
        isNote,
        title: `${isCollaboratore ? 'collaboratore' : 'dipendente'}: ${state.data?.codice} - ${state.data?.cognome} ${state.data?.nome}`,
        vCodDes: isCollaboratore ? 'TAG-ALERT-COLL' : 'TAG-ALERT-DIPTE',
    }))
    const getPeriodi = computed(() => {
        const startDate = toMoment(parametri.periodoDal)
        const endDate = toMoment(parametri.periodoAl)

        const months: Array<string> = []

        if (!startDate || !endDate) return months

        while (!startDate.isAfter(endDate, 'month')) {
            months.push(startDate.endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')) //startOf('day')
            startDate.add(1, 'month')
        }

        return months
    })
    const getSelectedPeriod = computed(() => selectedPeriod)
    /**
     * Ritorna il periodo delal riga attualmente selezionata nella tabella periodi
     */
    const getPeriodoTable = computed(() => {
        return dateUty.toObject(dataPeriodi.value[selectedPeriod.row]?.dataRiferimento)
    })
    const getSelectedRow = computed(() => dataPeriodi.value[selectedPeriod.row])
    const getTipoDitta = computed(() => {
        return state?.data?.ditta?.dittaGenerale?.tipoDitta
    })
    const getToSave = computed(() => toSave.value)
    const isDiario = computed(() => {
        return diarioStudio.value && diarioDitta.value
    })
    const getUnlockForm = computed(() => unlockForm.value)
    //#endregion

    //#region ACTIONs - FUNZIONI
    /**
     * Routine per ottenere la condizione di abilitazione o meno del diario nelle pagine del dettaglio.
     * Viene effettuata una chiamata allo studio e solo se e' previsto il diario viene effettuata
     * la chiamata per la relativa ditta. In caso in cui i dati dello studio siano gia' stati scaricati
     * non viene effettuata una nuova chiamata a meno che non venga fozata.
     * @param props props delle pagine di Uniemens
     * @param forceDownload se true forza il download dei dati del diario relativi allo studio
     */
    async function getAbilitazioneDiario(
        props: BaseUniemensDipteProps,
        forceDownload: boolean = false,
    ) {
        if (!parametri.periodoAl) return

        if (!diarioStudio.downloaded || forceDownload) {
            await getDiarioStudio()
        }
        //Se lo studio non ha abilitato il diario non faccio la chiamata per il diario della Ditta
        if (!diarioStudio.value || (!forceDownload && diarioDitta.downloaded)) return

        getDiarioDitta(props)
    }
    /**
     * Scarica i dati base relativi al funzionamento del dettaglio. I dati vengono poi salvati nelle relative
     * variabili d'ambiente.
     * @param props props relative alle pagine Uniemen Dipendenti e Collaboratori
     * @returns
     */
    async function getData(props: BaseUniemensDipteProps) {
        getAbilitazioneDiario(props)
        const periodoDiRiferimento = {
            year: Number(props.year),
            month: Number(props.month),
            day: Number(props.day),
        }
        const params: DiptePayload = {
            requireHasFutureValidity: false,
            periodoDiRiferimento,
            filter: `['id', '=', '${props.id}']`,
            select: JSON.stringify([
                'id',
                'codice',
                'cognome',
                'matricola',
                'nome',
                'codiceDitta',
                'containsDipteNoteCalc',
                'ditta.codiceDitta',
                'ditta.dittaGenerale.tipoDitta',
                'dipteUnitaProduttiva.dittaUP.unitaProduttiva',
                'dipteUnitaProduttiva.dittaUP.codiceDitta',
                'dipteUnitaProduttiva.dittaUP.dittaUPRagioneSociale.codice',
                'dipteUnitaProduttiva.dittaUP.dittaUPRagioneSociale.ragioneSociale',
                'dipteUnitaProduttiva.dittaUP.dittaUPRagioneSociale.denominazioneAggiuntiva',
                'dipteUnitaProduttiva.dittaUP.dittaUPTabellaAttivaList.*',
            ]),
        }
        try {
            const response = await pagheAPI.anagrafiche.dipendenti.dipte.get(params)
            if (!response?.data) return

            const { responseStatus, data } = response.data
            if (responseStatus.isSuccessful && data?.length) {
                const codice = data?.[0].codice ? `${data[0].codice}` : ''
                const cognome = data?.[0].cognome ? data[0].cognome : ''
                const nome = data?.[0].nome ? data[0].nome : ''
                //NON DOVREBBERO CAMBIARE CON LA NAVIGAZIONE IN ORIZZONTALE E VERTICALE
                datiDitta.value = {
                    ditta: data[0].ditta,
                    dittaUP: data[0].dipteUnitaProduttiva!.dittaUP,
                }
                //TODO: getter che prende da state.data oppure no ?
                dataHeader.title = [codice, `${cognome} ${nome}`]
                dataHeader.subtitle = utils.ragSocialeFromData(
                    data?.[0]?.dipteUnitaProduttiva?.dittaUP as Ditta,
                    true,
                )
                isNote.value = !!data?.[0]?.containsDipteNoteCalc //TODO:VERIFICARE
                state.data = data?.[0]
            }
        } catch (err) {
            console.error('Errore durante la chiamata: ', err)
        }
    }
    /**
     * In base al periodoAl specificato nei parametri effettua la chiamata per prendere il valore di
     * isDiario relativo alla ditta.
     * @param props pros della pagina
     * @returns
     */
    async function getDiarioDitta(props: BaseUniemensDipteProps) {
        if (!parametri.periodoAl) return

        try {
            const periodoDiRiferimento = dateUty.toObject(parametri.periodoAl)
            //chiamata per aggiornare il valore del diario

            const select = ['id', 'dittaUPOpzione.isDiario']

            if (+props.dittaPadreId > 0) {
                select.push('padre.id', 'padre.dittaUPOpzione.isDiario')
            }

            const params: DittaPayload = {
                requireHasFutureValidity: false,
                periodoDiRiferimento,
                filter: `['id', '=', '${props.dittaId}']`,
                select: JSON.stringify(select),
            }

            const response = await pagheAPI.anagrafiche.ditte.ditta.get(params)
            if (!response?.data) return
            const { responseStatus, data } = response?.data
            if (responseStatus.isSuccessful) {
                diarioDitta.downloaded = true

                diarioDitta.value =
                    +props.dittaPadreId > 0
                        ? get(
                              data?.[0],
                              'dittaUPOpzione.isDiario',
                              get(data?.[0], 'padre.dittaUPOpzione.isDiario', false),
                          )
                        : get(data?.[0], 'dittaUPOpzione.isDiario', false)
            }
        } catch (err) {
            console.error('Errore durante la chiamata: ', err)
        }
    }
    /**
     * Chiamata per ottenere i dati relativi al DIARIO per lo studio attuale.
     * Il risultato viene salvato all'interno della variabile diarioStudio
     */
    async function getDiarioStudio() {
        try {
            const periodoDiRiferimento = dateUty.toObject(parametri.periodoAl)
            //FLAG PER GESTIONE DIARIO DELL STUDIO
            const paramsStudio = {
                periodoDiRiferimento,
                include: "['studioGeneraleList']",
                select: "['studioGeneraleList.isDiarioDatiElaborazione','id']",
            }
            const responseStudio = await pagheAPI.anagrafiche.studio.get(paramsStudio)

            if (responseStudio?.data?.responseStatus?.isSuccessful) {
                diarioStudio.downloaded = true
                diarioStudio.value =
                    responseStudio.data.data[0]?.studioGeneraleList[0]?.isDiarioDatiElaborazione ??
                    false
            }
        } catch (error) {}
    }
    /**
     * Ritorna il periodo dei parametri (periodoDal e periodoAl) in formato object in modo che possa
     * essere utilizzato all'interno delle query BE.
     * @param toSave indica se tornare solamente il periodoAL
     * @returns null o periodo in formato Object
     */
    function getPeriodoFromParametri(toSave: boolean = false): PeriodoDiRiferimento | null {
        if (parametri.periodoDal && parametri.periodoAl) {
            const periodoDal = dateUty.toObject(parametri.periodoDal)
            const periodoAl = dateUty.toObject(parametri.periodoAl)

            if (toSave && periodoAl != null) {
                return {
                    ...periodoAl,
                } as PeriodoDiRiferimento
            }

            return {
                ...periodoDal,
                yearTo: periodoAl?.year,
                monthTo: periodoAl?.month,
                dayTo: periodoAl?.day,
            } as PeriodoDiRiferimento
        }

        return null
    }
    /**
     * Inizializza i periodi della modale PARAMETRI
     */
    function initParametri() {
        parametri.periodoDal = dateUty.toISO(basePeriodoDal)
        parametri.periodoAl = dateUty.toISO(basePeriodoAl)
        initSelectedPeriod()
    }
    /**
     * Inizializza i dati relativi ai periodi (visualizzati in tabella) in base ai dati scaricati
     * a BE.
     */
    async function initTablePeriodi(
        props: BaseUniemensDipteProps,
        resetSelectedPeriod: boolean = true,
    ) {
        //const periodoCall = dateUty.toObject(parametri.periodoAl)
        const result = await pagheAPI.risultati.elabDipteUE.get({
            //periodoCall,
            periodoDiRiferimento: {
                day: props.day,
                month: props.month,
                year: props.year,
            },
            select: JSON.stringify([
                'id',
                //'idElabDipte',
                'codTipoContribuzione',
                'elabDipte.id',
                'elabDipte.idDipte',
                'elabDipte.idDittaUp',
                'elabDipte.dataRiferimento',
                'elabDipte.tipoElaborazione',
                'elabDipte.dittaUp.unitaProduttiva',
                'elabDipte.dipte.ditta.unitaProduttiva',
                'elabDipte.dipte.dipteUnitaProduttiva.dittaUP.unitaProduttiva',
                'elabDipte.unitaProduttiva',
                'elabDipteUERetribList.id',
                'elabDipteUERetribList.tipoLavoratore',
                //'elabDipte.dipte.dipteRapporto.codQualifica',
            ]),
            include: JSON.stringify(['elabdipte']),
            filter: JSON.stringify([
                ['elabDipte.iddipte', '=', props.id],
                'and',
                ['elabDipte.dataRiferimento', '>=', `${parametri.periodoDal}`],
                'and',
                ['elabDipte.dataRiferimento', '<=', `${parametri.periodoAl}`],
            ]),
        })

        const dataTable = orderBy(result?.data.data, ['elabDipte.dataRiferimento'])
        dataPeriodi.value = [] //RESET

        dataTable.forEach(el => {
            //devo creare righe aggiuntive in base al tipo lavoratore

            el.elabDipteUERetribList?.forEach(tipoLav => {
                dataPeriodi.value.push({
                    id: el?.id as number,
                    idElabDipte: el.elabDipte?.id as number,
                    idDittaUp: el.elabDipte?.idDittaUp as number,
                    dataRiferimento: el.elabDipte?.dataRiferimento as string,
                    tipoElaborazione: el.elabDipte?.tipoElaborazione as string,
                    codTipoContribuzione: el.codTipoContribuzione as string,
                    unitaProduttiva: el.elabDipte?.dittaUp?.unitaProduttiva as string,
                    tipoLavoratore: tipoLav.tipoLavoratore as string,
                    idTipoLavoratore: tipoLav.id as number,
                })
            })
        })

        if (resetSelectedPeriod) initSelectedPeriod()
    }
    /**
     * Inizializza il periodo selezionato della tabella dei periodi. Di default il periodo
     * selezionato e' quello relativo a basePeriodoAl che di default corrisponde al periodo di elaborazione.
     */
    function initSelectedPeriod() {
        if (
            route.meta.group === pagheRoutes.uniemens.dipendente.SETTINGS.DETTAGLIO.NAME &&
            route.params
        ) {
            //selectedPeriod.value = `${route.params.month}/${route.params.year} - ME`s
            //SELEZIONA IL PERIODO ATTUALE
            const rowPeriodo = dataPeriodi.value.findIndex(
                row => row.dataRiferimento === dateUty.toISO(basePeriodoAl),
            )
            setSelectedPeriod(rowPeriodo > -1 ? rowPeriodo : 0, 0)
        }
    }
    /**
     * Permette di aggiornare la riga e la colonna attualmente selezionata all'interno della tabella
     * dei periodi.
     * @param row riga selezionata
     * @param col colonna selezionata
     */
    function setSelectedPeriod(row: number, col: number) {
        selectedPeriod.row = row
        selectedPeriod.col = col
    }
    /**
     * Resetta il nome della child attualmente visualizzata nel dettaglio con il valore di default
     * (in tal caso la prima pagina del dettaglio).
     */
    function resetActiveChildName() {
        //TODO: mettere nome prima pagina del elenco
        activeChildName.value = isCollaboratore
            ? pagheRoutes.risultati.dettaglioCo.SETTINGS.COLLABORATORE_RISULTATI.NAME //TODO: CAMBIARE CON IL COLLABORATORE
            : pagheRoutes.uniemens.dipendente.SETTINGS.GENERALE.NAME
    }
    /**
     * Effettua il reset delle variabili di base che dipendono dalla pagina visualizzata
     * (lookup, dati salvati per il cambio TAB,, ecc.)considerando, nel caso, anche eventuale
     * navigazione verticale e orizzontale.
     * @param resetLookup se true vengono cancellati i dati relativi alle lookup
     * @param oNavigation se true si sta procedendo in navigazione orizzontale
     * @param vNavigation se true si sta procedendo in navigazione verticale
     */
    function resetPageData(
        resetLookup: boolean = true,
        oNavigation: boolean = false,
        vNavigation: boolean = false,
    ) {
        dataChild.name = null
        dataChild.origin = null
        toSave.value = false
        unlockForm.value = false

        if (resetLookup) {
            dataLookup.clear()
        }
        if (!oNavigation) {
            //operazioni da fare solo se non si sta navigando
            localTab.value = 0
            dataChild.extraData = null //solo se non sto navigando
            if (!vNavigation) {
                initParametri() //in navigazione non vanno resettati
            }
        }
        if (!vNavigation) {
            //DIVERSO DIPTE, IN TUTTI I CASI TRANNE IN VERTICALE
        }
    }
    /**
     * funzione di setting dei dati relativi alal mmodale PARAMETRI
     * @param newValue oggetto da assegnare ai nuovi parametri
     */
    function setParametri(newValue: StateParametriUniemens) {
        assignPropsFromObject(parametri, newValue)
    }
    /**
     * Aggiorna la variabile toSave
     * @param value booleano con valore relativo al toSave
     */
    function setToSave(value: boolean) {
        toSave.value = value
    }

    //#endregion

    return {
        activeChildName,
        dataChildFilled,
        dataChild,
        dataHeader,
        dataLookup,
        getAbilitazioneDiario,
        getData,
        getDataPeriodi,
        getPeriodo,
        getPeriodoFromParametri,
        getPeriodi,
        getPeriodoTable,
        getSelectedPeriod,
        getSelectedRow,
        getTipoDitta,
        getToSave,
        initSelectedPeriod,
        initTablePeriodi,
        isDiario,
        isNote,
        localTab,
        getNoteContext,
        parametri,
        state,
        resetActiveChildName,
        resetPageData,
        setSelectedPeriod,
        setParametri,
        setToSave,
        toSave,
        unlockForm,
    }
}

export default baseUniemensDettaglioState
