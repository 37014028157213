import Swal from 'sweetalert2'
import moment from 'moment'
class MessageBox {
    constructor() {}

    static async error(message) {
        await Swal.fire({
            allowOutsideClick: false,
            confirmButtonColor: '#DC3741',
            confirmButtonText: 'OK',
            html: message,
            icon: 'error',
            title: 'Errore',
            showCancelButton: false,
            showCloseButton: false,
            confirmButtonAriaLabel: 'Conferma operazione',
        })
    }

    static async info(message) {
        await Swal.fire({
            allowOutsideClick: false,
            confirmButtonColor: '#DC3741',
            confirmButtonText: 'OK',
            html: message,
            icon: 'info',
            title: 'Info',
            showCancelButton: false,
            showCloseButton: false,
            confirmButtonAriaLabel: 'Conferma operazione',
        })
    }

    static async warning(message) {
        await Swal.fire({
            allowOutsideClick: false,
            confirmButtonColor: '#E9C524',
            confirmButtonText: 'OK',
            html: message,
            icon: 'warning',
            title: 'Attenzione',
            showCancelButton: false,
            showCloseButton: false,
            confirmButtonAriaLabel: 'Conferma operazione',
        })
    }

    static ShowBEMessage(result, isError, callBack) {
        //visualizza un messaggio di popUP nel caso in cui il BE ritorna una risposta

        if (isError) {
            //caso di errore
            let text =
                result.data.responseStatus.message != null
                    ? result.data.responseStatus.message
                    : 'Errore sconosciuto.'
            MessageBox.ErrorMessage(
                { message: text },
                function () {
                    if (callBack) callBack()
                },
                null,
            )
        } else {
            if (
                result.data.responseStatus.alertMessages != null &&
                result.data.responseStatus.alertMessages.length > 0
            ) {
                let text = ''

                for (let i = 0; i < result.data.responseStatus.alertMessages.length; i++) {
                    text += result.data.responseStatus.alertMessages[i].message
                    text += '<br>'
                }

                MessageBox.BasePopupMessage(
                    {
                        showConfirmButton: true,
                        showDenyButton: false,
                        showCancelButton: false,
                        confirmButtonClass: true,
                        title: 'Avviso',
                        message: text,
                        confirmButton: 'OK',
                        icon: 'warning',
                        confirmButtonColor: '#E9C524',
                        confirmButtonAriaLabel: 'Conferma operazione',
                    },
                    function () {
                        if (callBack) {
                            callBack()
                        }
                    },
                    null,
                    null,
                    null,
                )
            } else {
                //caso in cui non si debba mostrare il POPUP di Alert
                if (callBack) {
                    callBack()
                }
            }
        }
    }

    static WarningMessage(settings, successCallBack, errorCallBack) {
        Swal.fire({
            title: settings.title ? settings.title : 'Attenzione',
            html: settings.html || null,
            text: settings.message || null,
            icon: 'warning',
            showCancelButton:
                settings.showCancelButton != undefined ? settings.showCancelButton : true,
            showCloseButton: true,
            allowOutsideClick: false,
            confirmButtonText: settings.button1 ? settings.button1 : 'OK',
            cancelButtonText: settings.button2 ? settings.button2 : 'Annulla',
            confirmButtonColor: '#dc3741',
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        }).then(function (result) {
            if (result.isConfirmed) {
                if (successCallBack) successCallBack()
            } else {
                if (errorCallBack) errorCallBack()
            }
        })
    }

    static ErrorMessage(settings, successCallBack, errorCallBack) {
        Swal.fire({
            title: 'Errore',
            html: settings.html || null,
            text: settings.message || null,
            icon: 'error',
            showCancelButton: false,
            showCloseButton: false,
            allowOutsideClick: false,
            confirmButtonText: 'OK',
            confirmButtonColor: '#dc3741',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        }).then(function (result) {
            if (result.isConfirmed) {
                if (successCallBack) successCallBack()
            } else {
                if (errorCallBack) errorCallBack()
            }
        })
    }

    static CloseMessage(
        showConfirmButton,
        successCallBack,
        denyCallBack,
        cancelCallBack,
        errorCallBack,
    ) {
        Swal.fire({
            title: 'Attenzione',
            text: showConfirmButton
                ? 'Sono state fatte delle variazioni, vuoi salvare?'
                : 'Rilevata la presenza di errori bloccanti, impossibile salvare i dati. NO per continuare senza salvare, ANNULLA per tornare alla pagina.',
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: false,
            showDenyButton: true,
            focusCancel: true,
            showConfirmButton: true,
            confirmButtonText: 'Si',
            denyButtonText: 'No',
            cancelButtonText: 'Annulla',
            confirmButtonColor: '#37B21C',
            allowOutsideClick: false,
            customClass: {
                confirmButton: showConfirmButton ? '' : 'disabled',
            },
            buttonsStyling: true,
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        }).then(function (result) {
            switch (true) {
                case result.isConfirmed && showConfirmButton:
                    if (successCallBack) successCallBack()
                    break
                case result.isDenied:
                    if (denyCallBack) denyCallBack()
                    break
                case result.isDismissed || !showConfirmButton:
                    if (cancelCallBack) cancelCallBack()
                    break
                default:
                    if (errorCallBack) errorCallBack()
                    break
            }
        })
    }

    static CFGenerationMEssage() {
        Swal.fire({
            title: 'Attenzione',
            html: 'Codice fiscale determinato automaticamente, controllarne la validit&agrave;.',
            icon: 'warning',
            confirmButtonColor: '#37B21C',
            allowOutsideClick: false,
            confirmButtonAriaLabel: 'Conferma operazione',
        })
    }

    static BasePopupMessage(
        settings,
        successCallBack,
        denyCallBack,
        cancelCallBack,
        errorCallBack,
    ) {
        Swal.fire({
            title: settings.title,
            text: settings.message,
            html: settings.message,
            icon: settings.icon,
            showCloseButton:
                settings.showCloseButton != undefined && settings.showCloseButton != null
                    ? settings.showCloseButton
                    : false,
            showCancelButton:
                settings.showCancelButton != undefined && settings.showCancelButton != null
                    ? settings.showCancelButton
                    : true,
            showDenyButton:
                settings.showDenyButton != undefined && settings.showDenyButton != null
                    ? settings.showDenyButton
                    : false,
            showConfirmButton:
                settings.showConfirmButton != undefined && settings.showConfirmButton != null
                    ? settings.showConfirmButton
                    : false,
            focusCancel: true,
            confirmButtonText: settings.confirmButton ? settings.confirmButton : 'SI',
            cancelButtonText: settings.cancelButton ? settings.cancelButton : 'ANNULLA',
            denyButtonText: settings.denyButton ? settings.denyButton : 'NO',
            confirmButtonColor: settings.confirmButtonColor
                ? settings.confirmButtonColor
                : '#37B21C',
            denyButtonColor: settings.denyButtonColor ? settings.denyButtonColor : '#FF0000',
            input: settings.showConfirmCheckbox ? 'checkbox' : '',
            inputValue: 0,
            allowOutsideClick: false,
            inputPlaceholder: settings.inputPlaceholder,
            inputValidator: result => {
                return !result && settings.invalidMessage
            },
            customClass: {
                confirmButton: settings.confirmButtonClass ? '' : 'disabled',
                denyButton: settings.denyButtonClass ? settings.denyButtonClass : 'btn-warning',
                cancelButton: settings.cancelButtonClass
                    ? settings.cancelButtonClass
                    : 'btn-secondary',
            },
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        }).then(function (result) {
            switch (true) {
                case result.isConfirmed:
                    if (successCallBack) successCallBack()
                    break
                case result.isDenied:
                    if (denyCallBack) denyCallBack()
                    break
                case result.isDismissed:
                    if (cancelCallBack) cancelCallBack()
                    break
                default:
                    if (errorCallBack) errorCallBack()
                    break
            }
        })
    }

    static CFWarningMessage(successCallBack, errorCallBack, message) {
        Swal.fire({
            title: 'Avviso',
            html: message
                ? message
                : 'Codice fiscale errato. Premere OK per proseguire comunque con il salvataggio, Annulla per ritornare alla pagina. (365)',
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            confirmButtonText: 'OK',
            cancelButtonText: 'Annulla',
            confirmButtonColor: '#dc3741',
            allowOutsideClick: false,
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        }).then(function (result) {
            if (result.isConfirmed) {
                if (successCallBack) successCallBack()
            } else {
                if (errorCallBack) errorCallBack()
            }
        })
    }

    static async asyncCloseMessage(showConfirmButton) {
        const style = document.createElement('style')
        style.type = 'text/css'
        style.innerHTML = '.htmlContainerMinWidth {min-width: 20em;}'
        document.getElementsByTagName('head')[0].appendChild(style)
        let sweetAlertResult = await Swal.fire({
            title: 'Attenzione',
            html: showConfirmButton
                ? 'Sono state fatte delle variazioni, vuoi salvare?'
                : 'Rilevata la presenza di errori bloccanti, impossibile salvare i dati.' +
                  '<br><br>CONTINUA per procedere senza salvare.' +
                  '<br>ANNULLA per rimanere nella pagina.',
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: false,
            showDenyButton: showConfirmButton,
            focusCancel: true,
            showConfirmButton: true,
            confirmButtonText: showConfirmButton ? 'Si' : 'Continua',
            denyButtonText: 'No',
            allowOutsideClick: false,
            cancelButtonText: 'Annulla',
            confirmButtonColor: '#37B21C',
            buttonsStyling: true,
            width: 'fit-content',
            customClass: {
                htmlContainer: 'htmlContainerMinWidth',
            },
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        })

        return sweetAlertResult
    }

    static async asyncCFWarningMessage(message) {
        let sweetAlertResult = await Swal.fire({
            title: 'Avviso',
            html: message
                ? message
                : 'Codice fiscale errato. Premere OK per proseguire comunque con il salvataggio, Annulla per ritornare alla pagina. (365)',
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'OK',
            cancelButtonText: 'Annulla',
            confirmButtonColor: '#dc3741',
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        })

        return sweetAlertResult
    }

    static async asyncBasePopupMessage(settings) {
        let sweetAlertResult = await Swal.fire({
            title: settings.title,
            text: settings.message,
            html: settings.message,
            icon: settings.icon,
            showCloseButton:
                settings.showCloseButton != undefined && settings.showCloseButton != null
                    ? settings.showCloseButton
                    : false,
            showCancelButton:
                settings.showCancelButton != undefined && settings.showCancelButton != null
                    ? settings.showCancelButton
                    : true,
            showDenyButton:
                settings.showDenyButton != undefined && settings.showDenyButton != null
                    ? settings.showDenyButton
                    : false,
            showConfirmButton:
                settings.showConfirmButton != undefined && settings.showConfirmButton != null
                    ? settings.showConfirmButton
                    : false,
            focusCancel: true,
            confirmButtonText: settings.confirmButton ? settings.confirmButton : 'SI',
            cancelButtonText: settings.cancelButton ? settings.cancelButton : 'ANNULLA',
            denyButtonText: settings.denyButton ? settings.denyButton : 'NO',
            confirmButtonColor: settings.confirmButtonColor
                ? settings.confirmButtonColor
                : '#37B21C',
            denyButtonColor: settings.denyButtonColor ? settings.denyButtonColor : '#FF0000',
            input: settings.showConfirmCheckbox ? 'checkbox' : '',
            inputValue: 0,
            allowOutsideClick: false,
            inputPlaceholder: settings.inputPlaceholder,
            inputValidator: result => {
                return !result && settings.invalidMessage
            },
            customClass: {
                confirmButton: settings.confirmButtonClass ? '' : 'disabled',
                denyButton: settings.denyButtonClass ? settings.denyButtonClass : 'btn-warning',
                cancelButton: settings.cancelButtonClass
                    ? settings.cancelButtonClass
                    : 'btn-secondary',
            },
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        })

        return sweetAlertResult
    }

    static async infoPopupMessage(infoMessage) {
        let sweetAlertResult = await MessageBox.asyncBasePopupMessage({
            title: 'Info',
            message: infoMessage,
            icon: 'info',
            showCloseButton: false,
            showCancelButton: false,
            showDenyButton: false,
            showConfirmButton: true,
            confirmButton: 'OK',
            confirmButtonClass: true,
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        })

        return sweetAlertResult
    }

    static async asyncShowBEMessage(result, isError) {
        //visualizza un messaggio di popUP nel caso in cui il BE ritorna una risposta

        if (isError) {
            //caso di errore
            let text =
                result.data.responseStatus.message != null
                    ? result.data.responseStatus.message
                    : 'Errore sconosciuto.'
            let resultMessage = await MessageBox.ErrorMessageAsync({ message: text })

            return resultMessage
        } else {
            if (
                result.data.responseStatus.alertMessages != null &&
                result.data.responseStatus.alertMessages.length > 0
            ) {
                let text = ''

                for (let i = 0; i < result.data.responseStatus.alertMessages.length; i++) {
                    text += result.data.responseStatus.alertMessages[i].message
                    text += '<br>'
                }
                let resultMessage = await MessageBox.asyncBasePopupMessage({
                    showConfirmButton: true,
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonClass: true,
                    title: 'Avviso',
                    message: text,
                    confirmButton: 'OK',
                    icon: 'warning',
                    confirmButtonColor: '#E9C524',
                    closeButtonAriaLabel: 'Chiudi finestra di dialogo',
                    cancelButtonAriaLabel: 'Annulla operazione',
                    denyButtonAriaLabel: 'Rifiuta operazione',
                    confirmButtonAriaLabel: 'Conferma operazione',
                })

                return resultMessage
            } else {
                //caso in cui non si debba mostrare il POPUP di Alert
                return null
            }
        }
    }

    static async ErrorMessageAsync(settings) {
        let sweetAlertResult = await Swal.fire({
            title: 'Errore',
            text: settings.message,
            icon: 'error',
            showCancelButton: false,
            showCloseButton: false,
            allowOutsideClick: false,
            confirmButtonText: 'OK',
            confirmButtonColor: '#dc3741',
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
        })

        return sweetAlertResult
    }

    static async asyncWarningMessage(settings) {
        let sweetAlertResult = await Swal.fire({
            title: 'Avviso',
            text: settings.message,
            html: settings.message,
            icon: 'warning',
            showCancelButton: true,
            showCloseButton: true,
            allowOutsideClick: false,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Annulla',
            confirmButtonColor: '#dc3741',
            closeButtonAriaLabel: 'Chiudi finestra di dialogo',
            cancelButtonAriaLabel: 'Annulla operazione',
            denyButtonAriaLabel: 'Rifiuta operazione',
            confirmButtonAriaLabel: 'Conferma operazione',
            ...settings,
        })

        return sweetAlertResult
    }
}
class Toast {
    constructor() {}

    static success(message = 'Operazione completata con successo', callBack = null) {
        Swal.fire({
            customClass: {
                popup: 'colored-toast',
            },
            html: message,
            icon: 'success',
            iconColor: 'white',
            position: 'top-right',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true,
            toast: true,
        }).then(function () {
            if (callBack) callBack()
        })
    }
    static async asyncSuccess(message = 'Operazione completata con successo', callBack = null) {
        let result = await Swal.fire({
            customClass: {
                popup: 'colored-toast',
            },
            html: message,
            icon: 'success',
            iconColor: 'white',
            position: 'top-right',
            showConfirmButton: false,
            timer: 3500,
            timerProgressBar: true,
            toast: true,
        }).then(function () {
            if (callBack) callBack()
        })
        return result
    }

    static error(
        message = 'Operazione non riuscita.\n\nCorreggere i campi evidenziati in rosso e riprovare',
        callBack = () => {},
    ) {
        Swal.fire({
            customClass: {
                popup: 'colored-toast',
            },
            html: message,
            icon: 'error',
            iconColor: 'white',
            position: 'top-right',
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            toast: true,
        }).then(function () {
            if (callBack) callBack()
        })
    }

    static SuccessMessageRedirect(callBack) {
        Swal.fire({
            toast: true,
            icon: 'success',
            title: 'Success',
            position: 'top-right',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast',
            },
            showConfirmButton: false,
            timer: 1200,
            timerProgressBar: true,
        }).then(function () {
            if (callBack) callBack()
        })
    }

    static async SuccessMessageRedirectAsync(message = 'Operazione completata con successo') {
        let result = await Swal.fire({
            toast: true,
            html: message,
            icon: 'success',
            position: 'top-right',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast',
            },
            showConfirmButton: false,
            timer: 2200,
            timerProgressBar: true,
        })

        return result
    }

    static ErrorMessage() {
        Swal.fire({
            toast: true,
            icon: 'error',
            title: 'Error',
            position: 'top-right',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast',
            },
            showConfirmButton: false,
            timer: 1500,
            timerProgressBar: true,
        })
    }

    static async ErrorMessageAsync(
        message = 'Operazione non riuscita.\n\nCorreggere i campi evidenziati in rosso e riprovare',
    ) {
        let sweetAlertResult = await Swal.fire({
            toast: true,
            html: message,
            icon: 'error',
            position: 'top-right',
            iconColor: 'white',
            customClass: {
                popup: 'colored-toast',
            },
            showConfirmButton: false,
            timer: 3800,
            timerProgressBar: true,
        })

        return sweetAlertResult
    }
}
class MomentJS {
    constructor() {}

    static ConvertToIsoFormat(date) {
        const format = this.GetFormat(date)
        if (date != '' && format != '') return moment(date, format).format('YYYY-MM-DD[T]HH:mm:ss')
        return ''
    }

    static ConvertToIsoFormatFromShort(date, isLastOfMonth) {
        if (isLastOfMonth && date != '')
            return moment(date, 'MM/YYYY').endOf('month').format('YYYY-MM-DD[T]HH:mm:ss')
        if (date != '') return moment(date, 'MM/YYYY').format('YYYY-MM-DD[T]HH:mm:ss')
        return ''
    }

    static ConvertRegularFormat(date, month = false) {
        if (!date) return ''
        let format = month ? 'MM/YYYY' : 'DD/MM/YYYY'
        return moment(date, 'YYYY-MM-DD[T]HH:mm:ss').format(format)
    }

    static GetPeriodoRiferimentoFromIso(date) {
        //vedere se includere o meno il giorno
        let periodoDiRiferimento = {
            year: 0,
            month: 0,
        }
        if (date != null) {
            let temp = moment(date, 'YYYY-MM-DD[T]HH:mm:ss')
            periodoDiRiferimento.year = temp.format('YYYY')
            periodoDiRiferimento.month = temp.format('M')
        }
        return periodoDiRiferimento
    }

    static GetFullPeriodoRiferimentoFromIso(date) {
        //vedere se includere o meno il giorno
        let periodoDiRiferimento = {
            year: 0,
            month: 0,
            day: 0,
        }
        if (date != null) {
            let temp = moment(date, 'YYYY-MM-DD[T]HH:mm:ss')
            periodoDiRiferimento.year = temp.format('YYYY')
            periodoDiRiferimento.month = temp.format('M')
            periodoDiRiferimento.day = temp.format('DD')
        }
        return periodoDiRiferimento
    }

    static GetFormat(date = '') {
        if (!date) return ''

        if (date.length == 'M/YYYY'.length) return 'M/YYYY'
        if (date.length == 'MM/YYYY'.length) return 'MM/YYYY'
        if (date.length == 'YYYY-MM-DD[T]HH:mm:ss'.length) return 'YYYY-MM-DD[T]HH:mm:ss'
        if (date.length == 'DD/MM/YYYY'.length) return 'DD/MM/YYYY'
        if (date.length == 'YYYY-MM-DDTHH:mm:ss'.length) return 'YYYY-MM-DD[T]HH:mm:ss'
    }
}

export { MessageBox, Toast, MomentJS }
