import { camelCase, castArray, get, kebabCase } from 'lodash'
import { type MaybeRef, toValue } from 'vue'

import { arrayToMap, mapToArray } from '@/scripts/utils/array'
import { filterDate } from '@/composables/indexDB'
import { pagheAPI } from '@/api'

import dateUty, { DateType } from '@/scripts/services/date'

export type LookupType = {
    api: any
    extraFields?: string | string[]
    extraParams?: any[]
    filter?: string
    fnData?: (items: any[]) => any[]
    method?: string
    payload?: Payload
    toMap?: boolean
}

export class LookupMap extends Map {
    //TODO: EVOLUTIVA - La discrimminante e' il periodo ma potrebbe essere qualsiasi campo comune
    //ai items salvati nell array (o nella mappa) e dunque filterDate dovrebbe essere una funzione
    //generica che viene passata quando si istanzia LookupMap nello store in modo da renderlo generico
    private _periodo: MaybeRef<DateRec | null> = null
    //CACHE CON PERIODI DI PARTENZA GIA FILTRATI
    private filterdMap = new Map()
    clear(): void {
        super.clear()
        this.filterdMap.clear()
        this._periodo = null
    }

    get(key: any) {
        if (!this.periodo) return super.get(key)

        const keys = `${dateUty.toISO(toValue(this.periodo))}${key}`
        if (this.filterdMap.has(keys)) return this.filterdMap.get(keys)

        //CASO IN CUI SI TRATTA DI UN ARRAY
        if (Array.isArray(super.get(key)) && super.get(key)) {
            this.filterdMap.set(
                keys,
                super.get(key).filter((el: any) => {
                    if (!el.dataInizio || !el.dataFine) return el
                    return filterDate(toValue(this.periodo), el)
                }),
            )
            return this.filterdMap.get(keys)
        }

        //CASO IN CUI SI TRATTA DI UNA MAPPA
        if (!Array.isArray(super.get(key)) && super.get(key)) {
            const mapToSave = new Map()
            super.get(key).forEach((_data: any[], codice: string) => {
                const data = _data?.find((el: any) => filterDate(toValue(this.periodo), el))
                if (data) mapToSave.set(codice, data)
            })
            this.filterdMap.set(keys, mapToSave)
            return this.filterdMap.get(keys)
        }

        return super.get(key)
    }

    getDataPeriodo(key: string, periodoFilter: string) {
        this.periodo = periodoFilter

        return this.get(key)
    }
    set periodo(period) {
        this._periodo = period
    }
    get periodo() {
        return this._periodo
    }
}
/**
 *
 * @param lookups mappa che contiene le chiamata da effettuare a BE. La chiave utilizzata nella mappa verra' usatta per sal
 * @param store store in cui verranno salvate le lookup. Lo store deve avere la mappa dataLookup al suo interno dove verranno salvare le lookup.
 * @param periodoDiRiferimento se presente viene utilizzato per ogni chiamata commpreso le vCodDes. E' sempre possile, tranne per le vCodDes, specificare un periodo diverso all'interno del payload di ogni singola chiamata.
 */

export async function getLookup(
    lookups: Map<string, LookupType>,
    store: any,
    periodoDiRiferimento?: any,
) {
    //in caso di dati multipli per un codice (es. piu validita per lo stesso codice) potremmo avere
    //piu elementi e non uno solo, quindi la mappa sara' del tipo chiave -> array. Per ora questo
    //succede solo se si usa LookupMap; nel caso si puo' rendere generico ma bisonga passare la funzione
    //discriminante per selezionare l'elemento.
    function toMap(
        lookup: LookupType,
        items: any[] | null,
        toArray: boolean = store?.dataLookup instanceof LookupMap,
    ) {
        const map = arrayToMap(
            items,
            fields.concat(...castArray(lookup?.extraFields || [])),
            'codice',
            toArray,
        )
        return map
    }

    const fields = ['codice', 'descrizione', 'id', 'dataInizio', 'dataFine']

    // Tabelle VCodDes
    let query = Array.from(lookups).filter(
        ([key, lookup]) => !store.dataLookup.get(key) && lookup.api === pagheAPI.tabelle.vCodDes,
    )
    if (query.length) {
        const basePayload: Payload = {
            filter: query.map(lookup => kebabCase(lookup[0]).toUpperCase()),
        }
        if (periodoDiRiferimento) basePayload.periodoDiRiferimento = periodoDiRiferimento

        let result = await pagheAPI.tabelle.vCodDes.getVCodDesData(basePayload)
        result.forEach((tabV: VCodDes) => {
            const name = camelCase(tabV.funzione as string)
            if (!name) return //caso funzione non esiste
            store.dataLookup.set(
                name,
                [true, undefined].includes(lookups.get(name)?.toMap)
                    ? toMap(lookups.get(name)!, tabV.vcodDesElementoList)
                    : tabV.vcodDesElementoList,
            )
        })
    }

    // Tutte le altre
    query = Array.from(lookups).filter(
        ([key, lookup]) => !store.dataLookup.get(key) && lookup.api !== pagheAPI.tabelle.vCodDes,
    )
    if (query.length) {
        await Promise.all(
            query.map(async ([key, lookup]) => {
                let method = get(lookup.api, `${lookup.method}`, lookup.api.get)?.bind(lookup.api) //altrimmenti le classi che chiaano this.get non funzionano
                if (!method) throw new Error('queryLookup: metodo non definito')
                const select = fields.concat(...castArray(lookup.extraFields || []))
                const basePayload: Payload = {
                    filter: lookup.filter || null,
                    select: JSON.stringify(select),
                }
                if (periodoDiRiferimento) basePayload.periodoDiRiferimento = periodoDiRiferimento
                const payload = lookup.payload ? { ...basePayload, ...lookup.payload } : basePayload
                const params = lookup.extraParams ? [payload, ...lookup.extraParams] : [payload]
                const result = await method(...params) //passaggio parammetri extra al metodo
                let data =
                    result?.data?.data ||
                    result?.data?.payload ||
                    (Array.isArray(result) ? result : [])
                if (lookup.fnData) data = lookup.fnData(data)
                store.dataLookup.set(
                    key,
                    [true, undefined].includes(lookup?.toMap) ? toMap(lookup, data) : data,
                )
            }),
        )
    }
}
